import React from "react"

import { Container } from 'reactstrap' 
import LayoutEsp from '../../components/layoutEsp'
import SEO from "../../components/seo"
import Slider from '../../components/slider-howis' 
import truckAbsolute from '../../assets/images/how.png' 
import worldIcon from '../../assets/images/world.png' 
 
export default () => (
  <LayoutEsp>
    <SEO title="Como manejamos" />
    <Slider  title="COMO MANEJAMOS ?"/>
	<div className="world-brand" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="world-icon" src={worldIcon} alt="worldIcon"/>
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
			  	<h4 className="surpassing"><b>SOBREPASANDO</b> TODAS LAS FRONTERAS DE <b>NOSOTROS</b> A <b>TI</b></h4>  
			  </div>
			</div>
		</Container>
	</div>
	
	
	<div className="point-crossed-background2" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<h4 className="serviceQuality"><b>¿ COMO CONDUCIMOS ? </b></h4> 
				<img className="truckAbsolute3" src={truckAbsolute} alt="worldIcon"/>  
			  </div> 
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12"> 
				<div  className="col-lg-12 col-md-12 col-sm-12 col-xs-12 whiteBack"> 
					<p className="grayP">{'Nos preocupamos por el desempeño de nuestros conductores en la carretera.'}</p> 
					<p className="grayText2">Mandanos un correo a <a href="mailto:info@gutierreztrucking.com">info@gutierreztrucking.com</a></p>
				</div>
			  </div> 
			</div>
		</Container>
	</div>  
  </LayoutEsp>
)
